import React from "react";
import { AppBar, Box, Toolbar, Button, Avatar, Typography, IconButton, Tooltip  } from "@mui/material";
import styles from "./Header.module.css";
import { AccountCircleOutlined, Logout, AdminPanelSettings } from "@mui/icons-material";

const Header = ({user, onLogout, onAdmin}) => {

    const adminCmp = () => {
        return (
            <Tooltip title="Перейти в Админку">
                <IconButton 
                    color="inherit"
                    
                >
                    <AdminPanelSettings />
                </IconButton>
            </Tooltip>
        );  
    };

    return (
        
            <AppBar 
                position="sticky"
                className={styles.appbar}
                sx={{mb: 1}}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <Avatar sx={{mx: 1}}>
                        <AccountCircleOutlined />
                    </Avatar>
                    <Typography 
                        sx={{mx: 1}}
                        className={styles.login}
                    >
                        {user}    
                    </Typography>
                    
                    <Box sx={{ flexGrow: 1 }} />
                    {
                        adminCmp()
                    }
                    <Tooltip title="Выйти из кабинета">
                        <IconButton 
                            color="inherit"
                            onClick={onLogout}
                        >
                            <Logout />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            
        
    );


    /*return (
        <Box sx={{ flexGrow: 1, m: 1}}>
            <AppBar position="sticky" className={styles.appbar}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <Avatar sx={{mx: 1}}>
                        <AccountCircleOutlined />
                    </Avatar>
                    <Typography 
                        sx={{mx: 1}}
                        className={styles.login}
                    >
                        {user}    
                    </Typography>
                    
                    <Box sx={{ flexGrow: 1 }} />
                    {
                        adminCmp()
                    }
                    <Tooltip title="Выйти из кабинета">
                        <IconButton 
                            color="inherit"
                            onClick={onLogout}
                        >
                            <Logout />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
        </Box>
    );*/
};
export default Header;