import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import styles from './App.module.css'
import './App.css'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

import LoginPage from 'views/Auth/Login/LoginPage';
import RegistrationPage from 'views/Auth/Registration/RegistrationPage';
import MainPage from 'views/Kabinet/Main/MainPage';
import { KabinetRoute } from 'routing';

function App() {
  return (
    <main role='main' className={styles.wrapper}>
      <Routes>
        <Route path='/login' element={<LoginPage />}/>
        <Route path='/registration' element={<RegistrationPage />}/>
        
        <Route element={<KabinetRoute />}>
          <Route path='/kabinet/*' element={<MainPage />}/> 
        </Route>
        
        <Route path='*' element={<h2>Страница не найдена!</h2>} />
      </Routes>  
    </main>

    
  );
}

export default App;
