import { Avatar, Button, Grid, IconButton, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TextField, CheckBox } from "components/forms";
import React, { useState } from "react";
import styles from "./RegistrationForm.module.css";
import { HidingError } from "components/errors";

import Chb from '@mui/material/Checkbox';
import LoginIcon from '@mui/icons-material/Login';
import { Visibility, VisibilityOff, AccountCircle, LockOutlined, Password} from "@mui/icons-material";
import { /*useController, */useForm } from "react-hook-form";
import { testEmail } from "utils/regexp";
import { registerUser, loginUser } from "store/auth/authActions";
import { clearError, setShowError } from "store/auth/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAuthService } from "components/hoc";


const RegistrationForm = ({ authService: service, gotoLogin}) => {
    
    const [showPassword, setShowPassword] = useState(false);
    //const [name, setName] = useState('name');

    
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { control,
            handleSubmit,
            formState: {isSubmitting, isDirty, isValid} } = useForm({
            defaultValues: {
                name: '',
                password: '',
                retry: ''
            },
            mode: 'onChange'
        }
    );
    const dispatch = useDispatch();
    const {loading, error, showError, userInfo, success} = useSelector(
        (state) => state.auth
    )

    const navigate = useNavigate()
    /*useEffect( () => {
        if(success) navigate('/login')
        if(userInfo) navigate('/kabinet')
    }, [navigate, userInfo, success]);*/

    const submitForm = (data) => {
        data.email = data.name.toLowerCase();
        dispatch(registerUser({service, data}));
    };
        
    const hideError = () => {
        dispatch(setShowError(false));
        setTimeout( () => dispatch(clearError()), 1000);
    };

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <HidingError open={showError} message={error} onClose={hideError}/>
            <Grid>
                <Paper elevation={10} className={styles.paper}>
                    <Grid align='center'>
                        <Avatar className={styles.avatar} sx={{ width: 56, height: 56 }}>
                            <LockOutlined />
                        </Avatar>
                        <h2>Регистрация</h2>
                    </Grid>
                    <TextField 
                        control={control}
                        name="name"
                        rules={{ validate: testEmail }}
                        label="Имя пользователя"
                        sx={{marginTop: 1}}
                        variant="standard"
                        placeholder="Ведите адрес электронной почты" 
                        helperText="Некорретный адрес электронной почты"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                        }}
                        required />                   
                    <TextField 
                        control={control}
                        label="Пароль"
                        name="password"
                        rules={{ required: true, minLength: 8 }}
                        sx={{marginTop: 1}}
                        variant="standard"
                        placeholder="Введите новый пароль" 
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        required
                        helperText="Некорретный пароль"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <Password />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword} 
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end" >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }} />  
                    <TextField 
                        control={control}
                        label="Подтверждение пароля"
                        name="retry"
                        rules={{ required: true, minLength: 8 }}
                        sx={{marginTop: 1}}
                        variant="standard"
                        placeholder="Повторите пароль" 
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        required
                        helperText="Пароли не совпадают"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <Password />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword} 
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end" >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }} />    
                    <LoadingButton 
                        variant="contained"
                        disabled={!isValid}
                        startIcon={<LoginIcon />}
                        loadingPosition="start"
                        loading={loading}
                        sx={{marginTop: 2}}
                        fullWidth
                        type="submit" >                                                                             
                        <span>Зарегистрироваться</span>
                    </LoadingButton>
                    <Typography align="center">
                        <Button variant="text" color="secondary" size="small">Сгенерировать надёжный пароль</Button>
                    </Typography>
                    <Stack 
                        direction="row"
                        alignItems="center"
                        sx={{
                            marginTop: 2,
                            backgroundColor: 'rgb(238, 238, 238)',
                            padding: 1
                        }}
                        justifyContent="space-between" >
                        <Typography color="secondary">Уже зарегистрированы?</Typography>
                        <Button 
                            variant="outlined"
                            color="secondary"
                            onClick={gotoLogin} >
                                Войти
                        </Button>                        
                    </Stack>                
                </Paper>
            </Grid>
        </form>

        
    );
};

export default withAuthService(RegistrationForm);