import React from 'react';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import App from 'app/App';
import { AuthServiceProvider } from 'components/context/authServiceContext';
import MockAuthService from 'services/auth/MockAuthService';
import AuthService from 'services/auth/AuthService';

async function enableMocking(enable) {

  if(!enable) return;
  if(process.env.NODE_ENV !== 'development') return;
  
 
  const { worker } = await import('./mock/browser')
 
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

const container = document.getElementById('root');
const root = createRoot(container);

export const authService = new AuthService();

enableMocking(true)
  .then(() => {
    root.render(
        <Provider store={store}>
          <AuthServiceProvider value={authService}>
            <Router>
              <App />
            </Router>
          </AuthServiceProvider>
        </Provider>  
    );
  });


