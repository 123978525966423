const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    selectedDevice: null,
    activeDetails: null
};

const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setSelectedDevice: (state, {payload}) => {
            state.selectedDevice = payload;            
        },
        setActiveDetails: (state, {payload}) => {
            state.activeDetails = payload;
        },
        resetDetails: (state) => {
            state.activeDetails = null;
        },
        resetDevice: (state) => {
            state.selectedDevice = null;
        }
    }
});

export const {
    setSelectedDevice,
    setActiveDetails,
    resetDetails,
    resetDevice
} = deviceSlice.actions;

export default deviceSlice.reducer;