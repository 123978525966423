import React from "react";
import LoginForm from "../LoginForm";
import styles from "./LoginPage.module.css";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken } from "store/auth/authActions";
import { withAuthService } from "components/hoc";

const LoginPage = ({ authService: service }) => {

    const navigate = useNavigate();
    const { userInfo, rememberUser, loading, error } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    

    useEffect(() => {
        if (userInfo) { 
          navigate('/kabinet');
        }
        else if(rememberUser) { 
            dispatch(refreshToken({service}));
        }
    }, [userInfo, navigate] );
    
    const gotoRegistration = () => {        
        navigate("/registration");
    };

    return (
        <>
            
            {
                !userInfo && /*loading==false &&*/ 
                    <div className={styles.page}>
                        <LoginForm gotoRegistration={gotoRegistration}/>
                    </div>
            }
        </>
        
    );
};

export default withAuthService(LoginPage);