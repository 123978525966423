import { Card, CardContent } from "@mui/material";
import styles from './DeviceDetails.module.css'
const DeviceDetails = ({item, details}) => {

    const {model="", hardType="", id=""} = item || {};
    
    return (
        <div className={styles.wrapper}>
            <Card sx={{height: '100%'}}>
                <CardContent>
                    <h1>{model} {hardType} {id}. {details?.text}</h1>
                </CardContent>
            </Card>
        </div>
        
        
    );
};

export default DeviceDetails;