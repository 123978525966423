import React from "react";
import RegistrationForm from "../RegistrationForm";
import styles from "./RegistrationPage.module.css";
import { useNavigate } from 'react-router-dom';

const RegistrationPage = () => {

    const navigate = useNavigate();
    const gotoLogin = () => {        
        navigate("/login");
    };

    return (
        <div className={styles.page}>
            <RegistrationForm gotoLogin={gotoLogin}/>
        </div>
        
    );
};

export default RegistrationPage;