import React, { useEffect, useState } from "react";
import styles from "./MainPage.module.css";
import { Header, Sidebar } from "components/layout";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import TabletAndroidOutlinedIcon from '@mui/icons-material/TabletAndroidOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { useGetAppsQuery } from "services/data/DataService";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/auth/authSlice";
import { FullScreenLoader } from "components/loaders";
import DevicePage from "../DevicePage";
import { FullScreenError } from "components/errors";

const Profile = () => {
    return (
        <h1 className="header">Профиль</h1>
    );
};

const Appstore = () => {

    const t = useGetAppsQuery('getApps');
    

    return (
        <h1 className="header">Магазин приложений</h1>
    );
};
const Licences = () => {
    return (
        <h1 className="header">Лицензии</h1>
    );
};

const MainPage = () => {

    const [collapsedBar, setCollapsedBar] = useState(false);

    const {userInfo, accessToken, rememberUser, error} = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => { 
        if(!accessToken && !rememberUser) {
            dispatch(logout());
        }
    }, [accessToken]);

    const onLogout = () => {
        dispatch(logout());
    };

    const onAdmin = () => {

    };

    if(error) {
        return <FullScreenError />;
    }

    if (!accessToken) {
        return <FullScreenLoader />;
    }

    return (
        <div className={styles.wrapper}>
            <Sidebar
                collapsed={collapsedBar}    
            >
                <MenuItem
                    icon={<MenuOutlinedIcon />}
                    onClick={ () => setCollapsedBar(!collapsedBar) }
                >
                    <h1>Кабинет</h1> 
                </MenuItem>
                <SubMenu label="Админка" icon={<AdminPanelSettingsOutlinedIcon />}>
                    <MenuItem icon={< AppsOutlinedIcon />}> Магазин приложений </MenuItem>
                    <MenuItem icon={< TextSnippetOutlinedIcon />}> Генератор лицензий </MenuItem>
                    <MenuItem icon={< PeopleAltOutlinedIcon />}> Управление клиентами </MenuItem>
                    <MenuItem icon={< TabletAndroidOutlinedIcon />}> Управление кассами </MenuItem>
                </SubMenu>
                <MenuItem 
                    icon={< AccountCircleOutlinedIcon/>}
                    component={<Link to="profile" className="link" />}
                > 
                    Профиль 
                </MenuItem>
                <MenuItem 
                    icon={< TabletAndroidOutlinedIcon />}
                    component={<Link to="devices" className="link" />}
                >
                    Кассы
                </MenuItem>
                <MenuItem
                    icon={< AppsOutlinedIcon />}
                    component={<Link to="appstore" className="link" />}
                >
                    Приложения
                </MenuItem>
                <MenuItem
                    icon={< TextSnippetOutlinedIcon />}
                    component={<Link to="licences" className="link" />}
                >
                    Лицензии
                </MenuItem>
            </Sidebar>
            <div className={styles.contentWrapper}>
                <Header
                    user={userInfo?.userName}
                    onLogout={onLogout}
                    onAdmin={onAdmin}
                />
                <section className={styles.content}>
                    <Routes>
                        <Route path="profile" element={<Profile />} />
                        <Route path="devices" element={<DevicePage />} />
                        <Route path="appstore" element={<Appstore />} />
                        <Route path="licences" element={<Licences />} />
                    </Routes>
                </section>
            </div>
        </div>
        
    );
};

export default MainPage;