import React from "react";
import { FormControlLabel } from "@mui/material";
import { useController } from "react-hook-form";

const CheckBox = ({name, controlForm: control, rules, ...otherProps}) => {
    
    const {
        field,
        fieldState: {invalid, isTouched, isDirty}, 
        formState: {touchedFields, dirtyFields}
    } = useController({name, control, rules});
    
    return (
        <FormControlLabel
            {...otherProps}  
            onChange={field.onChange}     // send value to hook form
            onBlur={field.onBlur}         // notify when input is touched/blur
            value={field.value}           // input value
            name={field.name}             // send down the input name
            inputRef={field.ref}          // send input ref, so we can focus on input when error appear
        />
    )
};

export default CheckBox;
