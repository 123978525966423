import React, { useState } from "react";
import styles from "./DevicePage.module.css";
import DeviceItem from "./DeviceItem";
import { Stack, Box, List, ListItem, Card } from "@mui/material";
import { useGetDevicesQuery } from "services/data/DataService";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDevice, setActiveDetails } from "store/device/deviceSlice";
import DeviceDetails from "./DeviceDetails";
import Paginator from "components/paginator/Paginator";


const DevicePage = () => {

    const  [pagination, setPagination] = useState({
        start: 0,
        limit: 5,
        page: 1
    });

    const changePage = (event, page) => {
        const start = (page - 1) * pagination.limit;
        setPagination({...pagination, start: start, page: page});
    };

    const dispatch = useDispatch();
    const {selectedDevice, activeDetails} = useSelector(state => state.device);

    const { data, isError,  isFetching, refetch } = useGetDevicesQuery({start: pagination.start, limit: pagination.limit});
    
    if(isFetching) return <div>Загрузка...</div>;
    if(isError) return <div>Ошибка!</div>;


    const onItemSelect = (item, details) => {
        dispatch(setSelectedDevice(item));
        dispatch(setActiveDetails(details));
    };

    const isItemSelected = (item) => item?.id===selectedDevice?.id;
    const isActiveDetails = (id) => {
        if(id===selectedDevice?.id) return activeDetails;
        else return '';
    };

    const renderItems = data.body.items.map((item, index) => {
        return  <DeviceItem 
                    key={item.id}
                    selected={isItemSelected(item)}
                    onSelect={onItemSelect}
                    activeDetails={isActiveDetails(item.id)}
                    { ...item} 
                />;
    });
    
    return (
        <>
            <div className={styles.left}>
                <div className={styles.devicelist}>
                    <div className={styles.devicelistbody}>
                        <List >
                            {renderItems}
                        </List>
                    </div>
                    
                    <Paginator 
                        changePage={changePage}
                        total={data.body.total}
                        pageSize={pagination.limit}
                        page={pagination.page}
                    />
                </div>
            </div>
            <div className={styles.right}>
                <DeviceDetails
                    item={selectedDevice}
                    details={activeDetails}
                />
            </div>
        </>    
        
    );
   
};

export default DevicePage;

