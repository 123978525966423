const { Container, Box } = require("@mui/material");

const FullScreenError = ({text}) => {

    const textBlock = text ? text : "Что-то пошло не так. Попробуйте чуть позже.";

    return (
        <Container sx={{ height: '95vh' }}>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{ height: '100%' }}
            >
                <div>{textBlock}</div>
            </Box>
        </Container>
    );
};

export default FullScreenError;