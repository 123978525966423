import React, { useEffect, useRef, useState } from "react";
import { Snackbar, Button, IconButton, Alert } from "@mui/material";

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex', marginLeft: '10px'}}>
        <CircularProgress variant="determinate" size={26} thickness={2.5} color='inherit' {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="inherit" sx={{fontSize: 10}}>
            {`${props.label}`}
          </Typography>
        </Box>
      </Box>
    );
  }

const HidingError = ({...otherProps}) => {
    
    const [duration, setDuration] = useState(5000);
    const [left, setLeft] = useState();
    const timer = useRef();

    const countdown = () => {
        timer.current = window.setInterval(() => {
            setLeft((prev) => (prev === undefined ? prev : Math.max(0, prev - 1000)));
        }, 1000);
    };

    useEffect(() => {
        if(otherProps.open && duration !== undefined && duration > 0) {
            setLeft(duration);
            countdown();
        }
        else {
            window.clearInterval(timer.current);
        }
    }, [otherProps.open, duration]);

    const handlePause = () => {
        window.clearInterval(timer.current);
    }
    const handleResume = () => {
        countdown();
    }

    const counter = left !== undefined ? `${Math.floor(left/1000)}с` : "";
    return (
        <Snackbar
            {...otherProps}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            autoHideDuration={5000}
            disableWindowBlurListener
            onMouseOver={handlePause}
            onMouseLeave={handleResume}
        >
            <Alert
                onClose={otherProps.onClose}
                severity="error"
                sx={{width: '100%'}}
                variant="filled"                
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{display: "inline-block"}}>{`${otherProps.message}`}</div>
                    <CircularProgressWithLabel value={left/50} label={counter}/>
                </div>
            </Alert>
              
        </Snackbar>
    );
}

export default HidingError;