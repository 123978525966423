import { Button, ButtonGroup, Card, CardActions, CardContent, ListItem, ListItemButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import Box from '@mui/material/Box';
import AndroidIcon from '@mui/icons-material/Android';
import styles from "./DeviceItem.module.css";
import { useState } from "react";
import {Apps, SettingsOutlined, TextSnippetOutlined, SyncAltOutlined} from '@mui/icons-material/'

//key":60022,"model":"Нева","hardId":"A43SDF3434","hardType":"TPS570Q","minSdk":19,"imei1":"11111111243","imei2":"2222222121","osVersion":"Android 8.0"}
const DeviceItem = ({id, model, hardId, hardType, minSdk, imei1, imei2,
     osVersion, license, lastConnect, onSelect, selected, activeDetails}) => { 

    const getLicenseText = () => {
        if(!license) return 'Лицензии нет';
    };

    const getConnectionText = () => {
        if(!lastConnect) return 'Связи с устройством ещё не было';
    };

    const getDeviceStyle = () => {
        return `${styles.device}`;
    };

    return (
        <ListItemButton
            className={styles.device}
            sx={{pr:1, pb: 1, pl:0, pt: 0}}
            disableRipple
            disableTouchRipple
            selected={selected}
        >   
            <Card sx={{width:'100%'}}> 
                <CardContent>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}> 
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{ color: 'text.primary', fontSize: 20 }}>
                                {model} {hardType} {id}
                            </Box>
                            <Typography sx={{ color: 'text.primary', fontSize: 13, fontStyle: 'italic' }}>
                                {getLicenseText()}
                            </Typography>
                            <Typography sx={{ color: 'text.primary', fontSize: 13, fontStyle: 'italic' }}>
                                {getConnectionText()}
                            </Typography>   
                        </Box>
                        
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <Box sx={{ color: 'text.secondary', fontSize: 13, lineHeight: '24px'}}>
                                HID: {hardId}
                            </Box>
                            <Box sx={{ color: 'text.secondary', fontSize: 13, lineHeight: '24px'}}>
                                IMEI 1: {imei1} IMEI2: {imei2}
                            </Box>
                            <Box sx={{display: 'flex', alignitems: 'baseline'}}>
                                <AndroidIcon sx={{color: "#36a958", mr: 1}}/>
                                <Box sx={{ color: 'text.secondary', fontSize: 13, display: 'flex', alignItems: 'center'}}>
                                    {osVersion} MinSDK: {minSdk}
                                </Box>
                            </Box>
                        </Box>
                    </Box>                                                  
                </CardContent>
                <CardActions sx={{display: 'flex', justifyContent: 'center'}}>
                    
                    <ToggleButtonGroup 
                        variant="text"
                        size="small"
                        value={activeDetails.value}
                        onChange={(event, value) => onSelect({model, hardType, id}, {value, text: event.target.textContent})}
                        exclusive
                        aria-label="Small button group"
                    >
                        <ToggleButton value="apps">
                            <Apps />
                            Приложения
                        </ToggleButton>
                        <ToggleButton value="lics">
                            <TextSnippetOutlined />
                            Лицензии
                        </ToggleButton>
                        <ToggleButton value="log">
                            <SyncAltOutlined />
                            Лог
                        </ToggleButton>
                        <ToggleButton value="prefs">
                            <SettingsOutlined />
                            Настройки
                        </ToggleButton>
                    </ToggleButtonGroup>
                        
                </CardActions>
            </Card>
        </ListItemButton>
    );
};

export default DeviceItem;