import { configureStore } from '@reduxjs/toolkit';
import { dataApi } from 'services/data/DataService';
import authReducer from 'store/auth/authSlice';
import deviceReducer from 'store/device/deviceSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    device: deviceReducer,
    [dataApi.reducerPath]: dataApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dataApi.middleware)
});
