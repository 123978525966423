import React from "react";
import { TextField as Text} from "@mui/material";
import { useController, useForm } from "react-hook-form";

const TextField = ({name, control, rules, ...otherProps}) => {
    
    const {
        field,
        fieldState: {invalid, isTouched, isDirty}, 
        formState: {touchedFields, dirtyFields}
    } = useController({name, control, rules});
    
    return (
        <Text  
          {...otherProps}
          onChange={field.onChange}     // send value to hook form
          onBlur={field.onBlur}         // notify when input is touched/blur
          value={field.value}           // input value
          name={field.name}             // send down the input name
          inputRef={field.ref}          // send input ref, so we can focus on input when error appear
          error={invalid}
          helperText={invalid ? otherProps.helperText : ""}
        />
    )
};

export default TextField;
