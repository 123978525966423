import axios from "axios";
import { ClientJS } from 'clientjs';

export default class AuthService {
    
    _apiBase = 'https://test.restart-kassa.ru/api/auth';
    fingerprint = ((new ClientJS()).getFingerprint() << 32) >> 32;
    
    doPostRequest = async (url, data) => {

        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const response = await axios.post(
            `${this._apiBase}${url}`,
            {...data, fingerprint: this.fingerprint},
            config                
        )

        return response.data;
    };

    registerUser = (user) => {
        return this.doPostRequest(`/reg/`, user);
    };

    loginUser = (user) => {
         return this.doPostRequest(`/login/`, user);
    };

    refreshToken = () => { 
        return this.doPostRequest(`/refresh/`, {fingerprint: this.fingerprint});
    };
};